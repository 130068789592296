<template>
  <div class="template-view-component">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="1120px"
      :title="`预览-${dataset.templateName}`"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :append-to-body="dataset.appendToBody"
    >
      <div class="template-view-container" v-loading="loading">
        <div
          v-for="item in list"
          :key="item.id"
          class="item"
          :style="{
            width: cardWidthReander(item),
          }"
        >
          <SingleCard :dataset="item" />
        </div>
      </div>
      <div class="tempate-view-buttons" v-if="dataset.from !== 'edit'">
        <el-button type="primary" @click="applyHandler">应用</el-button>
        <el-button class="cancel-button" @click="dialogCloseHandler"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SingleCard from "./singleCard.vue";
import {
  getCustomTemplateDetail,
  getTemplateDetailManage,
} from "@/api/ruge/bmsNew/dashboard.js";
import { cloneDeep } from "lodash";
export default {
  name: "template-view-component",
  components: {
    SingleCard,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  watch: {
    dataset: {
      handler(datas) {
        if (datas && datas.templateId) {
          this.initDatas(datas.templateId, datas.manage);
        }
      },
      deep: true,
    },
  },
  methods: {
    async initDatas(templateId, isManage) {
      this.loading = true;
      let resulst = null;
      if (isManage) {
        resulst = await getTemplateDetailManage({ templateId });
      } else {
        resulst = await getCustomTemplateDetail({ templateId });
      }
      this.list = cloneDeep(resulst);
      this.loading = false;
    },
    applyHandler() {
      this.$confirm(
        `应用后将覆盖当前已选择卡片，确认覆盖吗？`,
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: "再想想",
          type: "warning",
        }
      )
        .then(() => {
          this.$emit("apply", this.list);
          this.list = [];
        })
        .catch(() => {
          console.log(`取消覆盖`);
        });
    },
    dialogCloseHandler() {
      this.list = [];
      this.$emit("close");
    },
    cardWidthReander(item) {
      const marginMap = {
        1: 20,
        2: 10,
        3: 0,
      };
      const oneStep = 33.3;
      let finalWidth = 33.3;
      const cardSizeParse = JSON.parse(item.cardSize);
      finalWidth = cardSizeParse.mode * oneStep;
      return `calc(${finalWidth}% - ${marginMap[cardSizeParse.mode]}px)`;
    },
  },
};
</script>

<style lang="less">
.template-view-container {
  background: #f1f6fd;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  min-height: 80vh;
  margin-bottom: 20px;
  .item {
    width: 33.3%;
    border-radius: 5px;
    color: #000;
  }
}
.tempate-view-buttons {
  text-align: center;
  margin: 20px 0;
  .cancel-button {
    margin-left: 30px;
  }
}
</style>