var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template-view-component" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.dialogCloseHandler,
            width: "1120px",
            title: `预览-${_vm.dataset.templateName}`,
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "append-to-body": _vm.dataset.appendToBody,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "template-view-container",
            },
            _vm._l(_vm.list, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "item",
                  style: {
                    width: _vm.cardWidthReander(item),
                  },
                },
                [_c("SingleCard", { attrs: { dataset: item } })],
                1
              )
            }),
            0
          ),
          _vm.dataset.from !== "edit"
            ? _c(
                "div",
                { staticClass: "tempate-view-buttons" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.applyHandler },
                    },
                    [_vm._v("应用")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "cancel-button",
                      on: { click: _vm.dialogCloseHandler },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }